import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
		<StyledContainer>
			<WebinarBody
				viewAllowed={props.viewAllowed}
			>
				<div className="flexBox">
					<div className="block block--left">
						<div className="block__content">
							<h2 data-aos="fade-up">Overview</h2>
							<p data-aos="fade-up">
							With the viruses and timing constantly evolving, keeping current can be a daunting task. So, we’re pleased to invite 
							you to attend <b>Updates on Influenza, COVID-19 and RSV: Trends and Treatments</b>, a free webinar scheduled for Friday, 
							October 27th at 2pm, EST. Lindsey E. Fish, MD will lead an expansive discussion of the epidemiology of the viruses, 
							as well as their timely diagnosis, treatment guidelines, and predictions for the 2023-2024 season.</p>
							<p data-aos="fade-up">We’re excited to welcome presenter Lindsey E. Fish, MD, Medical Director at Peña Urgent Care Clinic, Denver 
							Health and Hospital System. Dr. Fish will highlight flu, COVID-19 and RSV data relevant to urgent 
							care including:</p>
								<ul data-aos="fade-up">
								<li>Epidemiology of 2022-2023 Influenza and RSV Season</li>
								<li>Epidemiology of 2022-2023 COVID-19</li>
								<li>Early Diagnosis Key to Effective Treatments (Test and Treat)</li>
								<li>Influenza Treatment Guidelines (who, what and when)</li>
								<li>COVID-19 Treatment Guidelines (who, what and when)</li>
								<li>Predictions based on Southern Hemisphere Respiratory Season</li>
								<li>Where we are currently at the start of the 2023/2024 Season</li>
								<li>Key Takeaways</li>
							</ul>
						</div>
					</div>

					<div className="block block--right">
						<div className={(props.pageName === 'display' | props.viewAllowed === false) ? "accessBox show" : "accessBox"}>
							<h3 data-aos="fade-up">Access Includes:</h3>
							<ul data-aos="fade-up">
								<li>Video recording replay</li>
							</ul>
						</div>
						<div className={(props.pageName === 'download') ? "downloadBox show showPDF" : "downloadBox"}>
							<h3 data-aos="fade-up">Download</h3>
						</div>
					</div>
				</div>

				<div className="block block--left">
					<div className="block__content presenter">
						<h2 data-aos="fade-up">About the Presenter</h2>
						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/fish.jpg"
								width={200}
								alt="Dr. Lindsey E. Fish"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Dr. Lindsey E. Fish is an internist in Denver, Colorado and is affiliated with Denver 
							Health. She received her medical degree from University of California (San Francisco) School of Medicine 
							and has been in practice between 11-20 years.</p>
						</div>
					</div>
				</div>
			</WebinarBody>
		</StyledContainer>
	)
}

class FullBody extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			viewAllowed: false
		}
	}

	componentDidMount = props => {
		if((this.props.publicView) || (localStorage.getItem(this.props.localStorageID)) ) {
			this.setState({
				viewAllowed: true
			})
		}
	}

	render = () => (
		<BodyLanding
			pageName={this.props.pageName}
			viewAllowed={this.state.viewAllowed}
			localStorageID={this.props.localStorageID}
		/>
	)
}

export default FullBody
